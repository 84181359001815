export const FCM_CONFIG =  {
  apiKey: 'AIzaSyB0CshFF9cmWSR9YnukaSg9lKU85gjeurA',
  authDomain: 'saga-web-test-bf014.firebaseapp.com',
  projectId: 'saga-web-test-bf014',
  storageBucket: 'saga-web-test-bf014.appspot.com',
  messagingSenderId: '1089155116339',
  appId: '1:1089155116339:web:9d8584ecc31719fbf46246',
};

export const VAP_ID_KEY = 'BE91oq0pgSSrkxo2VP8S7Q6w0gX_i7HmwvdjfI3NzWYTDSENTYZRXTfMVMvWLlgv-KZivRFa29KeIF9ihjfK-Zo';
