import { useEffect, useState } from 'react';
import styles from './App.module.scss';
import { Description, Description2 } from './component/Description';
import ImgScroll from './component/ImgScroll';
import Info from './component/Info';
import Install from './component/Install';
import Title from './component/Title';
import { useInstalledStore } from './store/main.store';
import { GAME_URL } from './config/env';
import { useFCM } from './hook/useFCM';
import GoToGame from './component/GoToGame';
import { BeforeInstallPromptEvent, useBeforeInstallPrompt, useInPWAChecker, useInstalledChecker } from './hook/usePWA';
import LoadingSpinner from './component/LoadingSpinner';
import { Comment } from './component/Comment';

const goToGame = () => {
  window.location.href = GAME_URL;
};

const supportInstallPWA = () => {

  const isSamsungBrowser = navigator.userAgent.indexOf('SamsungBrowser') > - 1;

  return ('getInstalledRelatedApps' in navigator) && !isSamsungBrowser;
};

function App() {

  const setInstalled = useInstalledStore((state) => state.setInstalled);

  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent>();
  const [installing, setInstalling] = useState(false);
  const [preparing, setPreparing] = useState(false);

  const [inApp, setInApp] = useState(false);

  useFCM();

  useInstalledChecker({ setInstalled, setInstalling });

  // 自定義安裝PWA前需要做的動作
  useBeforeInstallPrompt({ setDeferredPrompt, setInstalled });

  useInPWAChecker({ setInApp, goToGame });

  useEffect(() => {
    const isSupport = supportInstallPWA();
    if (!isSupport) {
      console.log('不支援安裝PWA');
      setInApp(true);
    }
  }, []);

  return inApp
    ? <GoToGame></GoToGame>
    : (
      <div className={styles.App}>
        { preparing ? <LoadingSpinner></LoadingSpinner> : null }
        <Title installing={installing} setInstalling={setInstalling} setInstalled={setInstalled}></Title>
        <Info></Info>
        <Install
          deferredPrompt={deferredPrompt} setDeferredPrompt={setDeferredPrompt}
          installing={installing} setInstalling={setInstalling}
          preparing={preparing} setPreparing={setPreparing}
        ></Install>
        <ImgScroll></ImgScroll>
        <Description></Description>
        <Comment></Comment>
        <Description2></Description2>
      </div>
    );
}

export default App;
