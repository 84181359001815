/* eslint-disable max-len */
import styles from './Description.module.scss';

export function Description() {

  return (
    <div>
      <div className={styles.title}>About this app</div>
      <div className={styles.content}>
        <p>Looking for a platform where you can claim FREE CREDITS worth 50₱? Play with maximum fun in our SLOTS, CASINO, BINGO, SPORTS, FISHING, POKER, and more! From top slot providers such as EpicWin ourselves, JILI, Fachai, PG Soft and others. Start playing without risking your financials by claiming our FREE CREDITS.&nbsp;</p>

        <p>Get ready for an extraordinary casino slots adventure with EpicWin Philippines! These aren&rsquo;t your typical, basic slot app - we&rsquo;ve got exclusive slot machines that bring the BEST characters, mind-blowing graphics, exciting games, BONUSES, and a whole lot more!&nbsp; If you&rsquo;re on the hunt for free casino games and MASSIVE JACKPOTS that will blow your mind, congratulations - you&rsquo;ve just stumbled upon the most epic game in the city</p>

        <p>Seeking a fresh gaming experience? Dive into our exclusive collection of free slot games crafted by us. With 💎over 200 slot games to choose from 💎, EpicWin ensures non-stop fun every day. Check out our most popular titles available for instant play:</p>

        <ul>
          <li>Born Pink: Join Jennie, Rosie, Lisa, and Jisoo on a born-to-love adventure!</li>
          <li>Guardians of Galaxy 3: Spin those reels and be the hero of the team.</li>
        </ul>

        <p>Craving a touch of the classics?</p>

        <ul>
          <li>JILI Super Ace: Bet and spin for an addictive casino slot experience.</li>
          <li>Fa Chai Lucky Fortunes: Double your luck and unlock riches beyond your dreams.</li>
        </ul>

        <p>Feeling lucky? Don&#39;t wait any longer! Treat yourself to an epic slot machine journey today with EpicWin Philippines &ndash; where the fun never ends!</p>

      </div>
    </div>
  );
}

export function Description2() {

  return (
    <div>
      <div>
        <p><strong>Promotions</strong></p>

        <p>We have legitimate and trusted promotions that are ensured by the oversight of the Philippine Amusement and Gaming Corporation (PAGCOR), the primary regulatory authority for the country&#39;s gaming industry.</p>

        <p>Once you&rsquo;ve claimed the complimentary 50₱ promotion at our Philippines online casino, you unlock a world of possibilities, particularly within the realm of casino slots promotions. These promotions extend beyond mere entertainment, serving as strategic tools for players to enhance their gaming experience and potentially boost your winnings. Complimentary spins or bonus credits allow players to explore diverse slot games, seizing lucrative opportunities while minimising the risk associated with using your own funds!</p>

        <p>EpicWin Slot Games Delights, Weekly Fortunes, and Mesmerizing Slot Excitement.</p>

        <ul>
          <li>Plunge into an exhilarating experience with over 200 spins on EpicWin&#39;s casino slots, where a plethora of additional rewards, bonuses, and prizes eagerly await you!</li>
          <li>Enjoy a FREE 50₱ bonus for new sign-ups, alongside a delightful array of surprises, jackpots, and exclusive VIP gifts in our thrilling casino slot games!</li>
          <li>Aim for the jackpot and immerse yourself in the extraordinary variety of slot machines at EpicWin, competing for phenomenal slot game jackpots!</li>
          <li>Discover our latest creation &ndash; an epic and rewarding series of slot games, exclusively crafted by EpicWin, featuring thrilling bonuses!</li>
          <li>Reap the benefits of EpicWin rewards! Participate in all of our casino games to magnify your rewards, unlocking fantastic prizes as a token of our appreciation!</li>
        </ul>

        <p>Explore our website and feel free to start playing anytime, anywhere at <a href='https://epicwin.ph/'>https://epicwin.ph/</a>&nbsp;</p>

        <p>Embark on a winning streak with our exhilarating free slot game, packed with thrilling victories! With a vast selection of hundreds of free slot games in our expansive lobby, the challenge is deciding which one will become your next favourite! Explore over 200 casino slots, explore the array of games, and let the excitement of winning guide your journey &ndash; our casino is open day and night, ensuring non-stop thrills!</p>

        <p>Whether you&#39;re a seasoned casino slots enthusiast or a newcomer seeking the enjoyment of free slot machine games, our casino has something for everyone. With a continuous influx of new slot games, you&#39;re bound to discover the perfect slot machine game that suits your preferences.</p>

        <p>EpicWin is a haven for SLOT GAME and LIVE CASINO enthusiasts. Immerse yourself in a realm of endless free slot games and frequent bonuses. Accumulate a fortune of wild treasures, including chips, coins, gems, and more. Good fortune is by your side in our casino! Join us to play casino slots, uncover a new favourite slot game, and hit jackpots in our exciting world of free slot machines!</p>

        <p>EpicWin Philippines places paramount importance on user data security and trustworthiness, adhering to stringent measures to ensure a safe gaming environment. Utilising advanced encryption protocols, the platform secures all data transmissions during user interactions, especially in financial transactions such as deposits and withdrawals. Rigorous user authentication, including usernames, passwords, and potential two-factor authentication, adds an additional layer of protection to user accounts.</p>

        <p>In full compliance with regulatory standards set by the Philippine Amusement and Gaming Corporation (PAGCOR), EpicWin upholds data protection laws and guidelines. The platform maintains an open and transparent privacy policy, providing users with clarity on the collection, processing, and storage of their data. Secure payment options, integrated with reputable providers, assure users that their payment information is handled with the utmost security.</p>

        <p>Access controls and authentication mechanisms restrict data access exclusively to authorised personnel. Regular security audits and assessments proactively identify and address potential vulnerabilities, showcasing our commitment to maintaining a trustworthy gaming environment. Users are encouraged to uphold device security, and the platform&#39;s well-defined incident response plan ensures swift action in addressing any security concerns.</p>

        <p>EpicWin Philippines, with its robust security measures, regulatory compliance, and the endorsement of PAGCOR, instils trust among users, fostering confidence in the platform&#39;s dedication to data safety and reliability. The combination of these measures ensures a secure and enjoyable gaming experience, establishing EpicWin as a trustworthy destination for online entertainment in the Philippines.</p>

        <p><strong>Payment methods</strong></p>

        <p>Rev up your EpicWin adventure with GCASH and MAYA as your dynamic payment duo! Picture this: seamless transactions that are not just fast, but instant! Gcash brings unparalleled convenience with various funding options, while Maya dazzles with QR code, mobile wallet prowess, and secure bank transfers. More reasons to trust us right!</p>

        <p>But the excitement doesn&#39;t stop there! EpicWin users, get ready to choose your thrill &ndash; whether it&#39;s the widespread acceptance and versatility of Gcash or the futuristic QR code payments and discounts offered by Maya. The power is in your hands!</p>

        <p>Imagine the rush of claiming your complimentary 50₱ bonus using these electrifying payment methods. Gcash and Maya aren&#39;t just about payments; they&#39;re about unlocking a world of rewards, promotions, and cashback incentives that turbocharge your EpicWin journey.</p>

        <p>Flexibility meets accessibility as these mobile-centric options give you the freedom to make transactions anytime, anywhere. And here&#39;s the kicker &ndash; security is their middle name, ensuring your EpicWin experience is not just thrilling but also safe and secure.</p>
      </div>
    </div>
  );
}
