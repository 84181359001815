import styles from './ImgScroll.module.scss';

import imgScreenshot1 from '../resources/img/ingame-screenshot1.png';
import imgScreenshot2 from '../resources/img/ingame-screenshot2.png';
import imgScreenshot3 from '../resources/img/ingame-screenshot3.png';

export default function ImgScroll() {

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        <ImgView imgSrc={imgScreenshot1}></ImgView>
        <ImgView imgSrc={imgScreenshot2}></ImgView>
        <ImgView imgSrc={imgScreenshot3}></ImgView>
      </div>
    </div>
  );
}

function ImgView ({ imgSrc }: { imgSrc: string }) {

  return (
    <div className={styles.view}>
      <img src={imgSrc} alt='' />
    </div>
  );
}
