import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { useEffect } from 'react';
import { FCM_CONFIG, VAP_ID_KEY } from '../config/fcm';

export const useFCM = () => {
  useEffect(() => {

    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
      return;
    }

    Notification.requestPermission().then((permission) => {

      console.log('permission', permission);

      const firebaseConfig = FCM_CONFIG;

      const app = initializeApp(firebaseConfig);

      const messaging = getMessaging(app);

      getToken(messaging, { vapidKey: VAP_ID_KEY }).then((currentToken) => {
        if (currentToken) {
          console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
        } else {
        // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      });
    });
  }, []);
};
