import { create } from 'zustand';

export interface InstalledState {
  installed: boolean;
  setInstalled(newInstalled: boolean): void;
}

export const useInstalledStore = create<InstalledState>((set) => ({
  installed: false,
  setInstalled: (newInstalled: boolean) =>
    set((state) => {
      return {
        ...state,
        installed: newInstalled,
      };
    }),
}));
