import styles from './LoadingSpinner.module.scss';

export default function LoadingSpinner() {

  return (
    <>
      <span className={styles.loader}></span>
    </>
  );
}
