import { useEffect } from 'react';

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 *
 */
export interface BeforeInstallPromptEvent extends Event {

  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed',
    platform: string
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;

}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

export function useInstalledChecker({ setInstalled, setInstalling }: { setInstalled: (isInstalled: boolean) => void, setInstalling: (isInstalling: boolean) => void}) {

  useEffect(() => {

    // 當使用者安裝App時觸發，設定已安裝狀態
    const handleAppInstalled = () => {
      console.log('App Installed');

      localStorage.setItem('isAppInstalled', 'true');
    };

    window.addEventListener('appinstalled', handleAppInstalled);

    // 檢查是否已安裝
    if (localStorage.getItem('isAppInstalled')) {
      setInstalled(true);
    }

    return () => {
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);
}

/**
 * 自定義安裝PWA前需要做的動作
 *
 * @export
 */
export function useBeforeInstallPrompt({ setInstalled, setDeferredPrompt }: { setInstalled: (isInstalled: boolean) => void, setDeferredPrompt: (e: BeforeInstallPromptEvent) => void }) {

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      console.log('beforeinstallprompt', e);
      setInstalled(false);
      localStorage.removeItem('isAppInstalled');

      // 防止Chrome 67及更早版本自動顯示安裝提示
      e.preventDefault();
      // 保存事件，以便稍後再觸發
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);
}

export function useInPWAChecker({ setInApp, goToGame }: { setInApp: (isInApp: boolean) => void, goToGame: () => void }) {

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setInApp(true);
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setInApp(true);
        goToGame();
      }
    }, 2000);

    return () => {
      clearInterval(timer);
    };
  }, []);
}
