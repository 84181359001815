import styles from './GoToGame.module.scss';

import { useEffect } from 'react';
import { GAME_URL } from '../config/env';
import imgTitleIcon192 from '../resources/img/logo192.png';

const getFbpCookie = () => {
  const cookies = document.cookie.split(';');
  const fbpCookie = cookies.find((cookie) => cookie.trim().startsWith('_fbp='));
  return fbpCookie ? fbpCookie.split('=')[1] : '';
};

export default function GoToGame() {

  useEffect(() => {
    let times = 0;
    const timer = setInterval(() => {
      const fbpValue = getFbpCookie();
      if (fbpValue) {
        clearInterval(timer);
        window.location.href = GAME_URL + `?fbp=${fbpValue}&pixelId=${process.env.REACT_APP_PIXEL_ID}`;
      }
      else {
        times++;
        if (times > 3) {
          clearInterval(timer);
          window.location.href = GAME_URL;
        }
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };

  }, []);

  return (
    <div className={styles.container}>
      <img src={imgTitleIcon192} alt='icon'></img>
    </div>
  );
}
