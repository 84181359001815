import styles from './Info.module.scss';

export default function Info() {

  return (
    <div className={styles.container}>
      <View props={{ value: '4.5', label: '10K reviews', withOutLine: false }} />
      <View props={{ value: '1 Million +', label: 'downloads', withOutLine: false }} />
      <View props={{ value: '18+', label: '18+', withOutLine: true }} />
    </div>
  );
}

function View({ props }: { props: { value: string, label: string, withOutLine: boolean }}) {

  return (
    <div className={styles.view}>
      <div className={`${styles['view-value']} ${props.withOutLine ? styles['with-border'] : ''}`}>
        {props.value}
      </div>
      <div className={styles['view-label']}>
        {props.label}
      </div>
    </div>
  );
}
