import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './Title.module.scss';

import imgTitleIcon from '../resources/img/logo192.png';
import { APP_NAME, LTD_NAME } from '../config/env';

type TitleProps = {
  installing: boolean,
  setInstalling: Dispatch<SetStateAction<boolean>>,
  setInstalled: (installed: boolean) => void,
};

export default function Title({ installing, setInstalling, setInstalled }: TitleProps) {

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (installing) {
      const timer = setInterval(() => {
        setProgress((prev) => {
          if (prev < 100) {
            return Math.min(prev + 2, 100);
          } else {
            clearInterval(timer);
            return prev;
          }
        });
      }, 200);
      return () => {
        clearInterval(timer);
      };
    }
  }, [installing]);

  useEffect(() => {
    if (progress === 100) {
      setInstalling(false);
      setInstalled(true);
    }
  }, [progress]);

  return (
    <div className={styles.header}>
      <div className={styles.icon}>
        <img className={`${styles['icon-img']} ${installing ? styles.installing : ''}`} src={imgTitleIcon} alt='icon'></img>
        <div className={`${styles.loading} ${installing ? styles.running : ''}`}>
          <svg viewBox='25 25 50 50'>
            <circle cx='50' cy='50' fill='none' r='20'></circle>
          </svg>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles['info-title']}>{APP_NAME}</div>
        {
          installing
            ? <div className={styles['info-progress']}>{progress}%</div>
            : <div className={styles['info-description']}>{LTD_NAME}</div>
        }
        <div className={styles['info-play']}> Verified by Play Protect</div>
      </div>
    </div>
  );
}
