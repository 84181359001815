import styles from './Comment.module.scss';

import { COMMENT_LIST, EVALUATE_PERCENT } from '../config/comment';

type CommentRowProps = {
  avatar: string,
  name: string,
  comment: string,
  starCount: number,
};

export function Comment() {

  return (
    <div className={styles.container}>
      <TotalEvaluate></TotalEvaluate>
      {
        Array.from(COMMENT_LIST).map((comment, index) => {
          return (
            <CommentRow key={index} avatar={comment.imgAvatar} name={comment.name} comment={comment.comment} starCount={comment.starCount}></CommentRow>
          );
        })
      }
    </div>
  );
}

export function TotalEvaluate() {

  return (
    <div className={styles['total-evaluate']}>
      <div>
        <div className={styles['evaluate-number']}>4.8</div>
        <div className={styles['evaluate-row']}>
          <StarRow startCount={4}></StarRow>
        </div>
      </div>
      <div>
        {
          Array.from(EVALUATE_PERCENT).reverse().map((evaluate, index) => {
            return (
              <EvaluateProgress key={index} starCount={EVALUATE_PERCENT.length - index} progress={evaluate}></EvaluateProgress>
            );
          })
        }
      </div>
    </div>
  );
}

export function EvaluateProgress({ starCount, progress }: { starCount: number, progress: number }) {

  return (
    <div className={styles['per-star-row']}>
      <div className={styles['per-star-row-number']}>{ starCount }</div>
      <div className={styles['per-star-row-progress']}>
        <div className={styles['progress-bar']} style={{ width: `${progress*100}%` }}></div>
      </div>
    </div>
  );
}

export function CommentRow({ avatar, name, comment, starCount }: CommentRowProps) {

  return (
    <div className={styles['comment-row']}>
      <header>
        <div className={styles.avatar}>
          <img src={avatar} alt=''></img>
          <div className={styles.name}>{name}</div>
        </div>
        <div className={styles.evaluate}>
          <div className={styles['evaluate-row']}>
            <StarRow startCount={starCount}></StarRow>
          </div>
        </div>
      </header>
      <div className={styles['comment-content']}>{comment}</div>
    </div>
  );
}

export function StarRow({ startCount }: { startCount: number }) {

  return (
    <>
      <StarSVG enabled={startCount >= 1}></StarSVG>
      <StarSVG enabled={startCount >= 2}></StarSVG>
      <StarSVG enabled={startCount >= 3}></StarSVG>
      <StarSVG enabled={startCount >= 4}></StarSVG>
      <StarSVG enabled={startCount >= 5}></StarSVG>
    </>
  );
}

export function StarSVG({ enabled = true }: { enabled?: boolean }) {

  return (
    <span className={`${styles.star} ${enabled ? '' : styles.disabled}`}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        <path d='M0 0h24v24H0z' fill='none'></path>
        <path d='M0 0h24v24H0z' fill='none'></path>
        <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'>
        </path>
      </svg>
    </span>
  );
}
