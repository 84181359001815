import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BeforeInstallPromptEvent } from '../hook/usePWA';
import { useInstalledStore } from '../store/main.store';
import styles from './Install.module.scss';

type InstallProps = {
  deferredPrompt: BeforeInstallPromptEvent | undefined,
  setDeferredPrompt: Dispatch<SetStateAction<BeforeInstallPromptEvent | undefined>>,
  installing: boolean,
  setInstalling: Dispatch<SetStateAction<boolean>>,
  preparing: boolean,
  setPreparing: Dispatch<SetStateAction<boolean>>,
};

export default function Install({ deferredPrompt, setDeferredPrompt, installing, setInstalling, preparing, setPreparing }: InstallProps) {

  const installed = useInstalledStore((state) => state.installed);

  const [prepared, setPrepared] = useState(false);

  useEffect(() => {
    if (deferredPrompt !== undefined) {
      setPrepared(true);
      setPreparing(false);
    }
  }, [deferredPrompt]);

  const installFunc = async () => {

    if (installing) {
      return;
    }

    // console.log('installFunc', deferredPrompt);
    // setInstalling(true);
    // return;

    // TODO: custom install alert

    if (deferredPrompt) {
      // 顯示安裝提示
      await deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        setInstalling(true);
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      setDeferredPrompt(undefined);
    }
  };

  const prepareInstall = () => {

    if (installing) {
      return;
    }

    if (preparing) {
      return;
    }

    setPreparing(true);

    setTimeout(() => {
      if (deferredPrompt !== undefined) {
        setPrepared(true);
      }
      setPreparing(false);
    }, 2000);
  };

  const playFunc = () => {

    if (installing) {
      return;
    }

    window.open('./', '_blank');
  };

  console.log('Install', { deferredPrompt, installing, preparing, prepared });

  return (
    <div className={`${styles['install-btn']} ${installing ? styles.installing : ''}`}>
      {
        installed
          ? <div className={styles['btn-view']} onClick={playFunc}>Play</div>
          : (
            prepared || deferredPrompt !== undefined
              ? <div className={styles['btn-view']} onClick={installFunc}>Install</div>
              : (
                preparing
                  ? <div className={`${styles['btn-view']} ${styles.installing}`}>Prepare...</div>
                  : <div className={styles['btn-view']} onClick={prepareInstall}>Prepare Install</div>
              )
          )
      }
    </div>
  );
}
