import imgAvatar01 from '../resources/img/avatar/01.png';
import imgAvatar02 from '../resources/img/avatar/02.png';
import imgAvatar03 from '../resources/img/avatar/03.png';
import imgAvatar04 from '../resources/img/avatar/04.png';
import imgAvatar05 from '../resources/img/avatar/05.png';
import imgAvatar06 from '../resources/img/avatar/06.png';
import imgAvatar07 from '../resources/img/avatar/07.png';
import imgAvatar08 from '../resources/img/avatar/08.png';
import imgAvatar09 from '../resources/img/avatar/09.png';
import imgAvatar10 from '../resources/img/avatar/10.png';

type TypeComment = {
  imgAvatar: string;
  name: string;
  comment: string;
  starCount: number;
};

export const EVALUATE_PERCENT = [0.01, 0.1, 0.12, 0.3, 0.9];

export const COMMENT_LIST: TypeComment[] = [
  {
    imgAvatar: imgAvatar01,
    name: 'PinoyPride',
    comment: 'yung update ngayon mas pina-smooth na lahat tapos sobrang angas ng mga bagong features',
    starCount: 5,
  },
  {
    imgAvatar: imgAvatar02,
    name: 'ManilaMaven',
    comment: 'Nagustuhan ko yung mga theme ng laro nila atsaka yung mga games na ginawa nila ang saya laruin hindi nakakaboring.',
    starCount: 4,
  },
  {
    imgAvatar: imgAvatar03,
    name: 'LuzonLad',
    comment: 'Nanalo ako ng doble gamit yung mga bonus nila ni hindi ko na nga kelangan mag labas ng sarili kong pera eh.',
    starCount: 5,
  },
  {
    imgAvatar: imgAvatar04,
    name: 'VisayasVixen',
    comment: 'Alam na alam nila pano gumawa ng slots, tamang tama katulad sa actual na slots mas nakakaadik at masaya laruin kesa sa mga ibang online casino na natry ko na',
    starCount: 5,
  },
  {
    imgAvatar: imgAvatar05,
    name: 'MindanaoMystic',
    comment: 'Ang ganda ng site na to kasi ibang iba kesa sa mga online casino na nasubukan ko na, mas pinagisipan yung mga laro tapos mas malaki maka cash out',
    starCount: 5,
  },
  {
    imgAvatar: imgAvatar06,
    name: 'AdoboMaster',
    comment: 'Ok na ok sa performance ang epicwin, Siguro mas gaganda pa kung mas madami pang laro na pagpipilian para lahat na ng choices meron.',
    starCount: 4,
  },
  {
    imgAvatar: imgAvatar07,
    name: 'BarongBoss',
    comment: 'Ok na ok sa performance ang epicwin, Siguro mas gaganda pa kung mas madami pang laro na pagpipilian para lahat na ng choices meron.',
    starCount: 5,
  },
  {
    imgAvatar: imgAvatar08,
    name: 'HaloHaloHaven',
    comment: 'Andaming laro dito hindi ko na alam anong uunahin kong subukan buti may demo natry ko muna bago ako naglabas ng pera',
    starCount: 5,
  },
  {
    imgAvatar: imgAvatar09,
    name: 'JeepneyJumper',
    comment: 'first time ko lang mag online casino nag deposit lang ako 100 tas nanalo agad ako ng 300 cash out ko agad eh, dumating naman agad sa account ko, apaka nice!',
    starCount: 4,
  },
  {
    imgAvatar: imgAvatar10,
    name: 'IslandIngenue',
    comment: 'Sa tagal ko ng naglalaro, eto yung may pinaka madaming mag bigay ng panalo at mga bonus na credit. Goods na goods!',
    starCount: 5,
  },
];
